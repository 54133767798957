<template>
  <div class="time" :class="{'active': time.status}">
    <span>{{ time.time }}</span>
  </div>
</template>

<script>
export default {
  name: "TerminTimeComponent",
  props: {
    time: {
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.time {
  border: 2px solid #efefef;
  background: #efefef;
  color: #dcdcdc;
  background: #ee7878;
  border: 2px solid #d76a6a;
  color: #ffffff;
  width: 15%;
  height: 75px;

  margin: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-weight: 500;
  cursor: default;

  //&.active {
  //  background: #f6f6f6;
  //  border: 2px solid #afafaf !important;
  //  color: #283828 !important;
  //  cursor: pointer;
  //}
  //
  //&.active {
  //  background: #548791;
  //  border: 2px solid #3a7986 !important;
  //  color: #ffffff !important;
  //  cursor: pointer;
  //  &:hover {
  //    background: #2f636e;
  //  }
  //}

  &.active {
    background: transparent;
    border: 2px solid #cecece !important;
    color: #656565 !important;
    cursor: pointer;
    &:hover {
      background: #e8e8e8;
      border: 2px solid #e8e8e8 !important;
    }
  }
}
</style>