<template>
  <v-row>
    <v-col cols="12" style="padding: 30px;">
      <h2>Termin</h2>
    </v-col>
    <v-col cols="12">
<!--      <select name="select">-->
<!--        <option-->
<!--            v-for="service in services"-->
<!--            :value="service.value"-->
<!--            :selected="service_value === service.value"-->
<!--            @click.prevent="serviceSelect(service)"-->
<!--        >{{ service.text }}</option>-->
<!--      </select>-->

      <div style="display:flex; flex-wrap: wrap;">
        <div
            class="sv"
            :class="{'sv_active': service_value === service.value}"
            v-for="service in services"
            @click.prevent="serviceSelect(service)"
        >
         <div style="display: flex; flex-direction: column; align-items: center; justify-content: center;">
           <strong style="font-weight: 500;">{{ service.text }}</strong>
           <span style="font-size: 14px; color: #6b6b6b">{{ service.subtitle }}</span>
         </div>
        </div>
      </div>
    </v-col>
    <v-col cols="6">
      <TerminComponent
          :service_plus="service_plus"
          :work_time="work_time"
          :booked_time="booked_time"
          :key="service_id"
          :step="step"
          :service_time="service_time"
      />
    </v-col>
    <v-col cols="6">
      <TerminComponent
          :service_plus="service_plus"
          :work_time="work_time"
          :booked_time="['09:45', '14:15']"
          :step="step"
          :key="service_id"
          :service_time="service_time"
      />
    </v-col>
  </v-row>
</template>

<script>
import TerminComponent from "./Components/TerminComponent";

export default {
  name: "TerminView",
  components: { TerminComponent },
  data() {
    return {
      work_time: {start: '08:00',end: '17:00'},
      booked_time: ['09:45', '10:30', '11:45', '14:15'],
      service_plus: true,
      service_time: 30,
      step: 15,

      is_bool: true,

      service_value: 'ms30',
      service_id: 1,

      services: [
        {id: 1,text: 'Массаж', subtitle: '30 мин.', value: 'ms30', service_plus: true, service_time: 30},
        {id: 2,text: 'Массаж', subtitle: '60 мин.', value: 'ms60', service_plus: true, service_time: 60},
        {id: 3,text: 'Массаж', subtitle: '65 мин.', value: 'ms65', service_plus: true, service_time: 65},
        {id: 4,text: 'Массаж', subtitle: '90 мин.', value: 'ms90', service_plus: true, service_time: 87},
        {id: 5,text: 'Подстричь волосы', subtitle: 'Средней длины', value: 'pw', service_plus: false, service_time: 30},
      ]
    }
  },
  methods: {
    serviceSelect(service) {
      this.service_value = service.value
      this.service_plus = service.service_plus
      this.service_time = service.service_time
      this.service_id = service.id
    }
  }
}
</script>

<style lang="scss">
.sv {
  cursor: pointer;
  width: 33.333%;
  //width: 25%;
  height: 100px;
  border: 1px solid #cecece;
  display: flex;
  align-items: center;
  justify-content: center;

  &_active {
    background: #d9d9d9;
  }
}
</style>