<template>
  <div>
    {{booked_time}}
    <v-col cols="3">dasasd</v-col>
    <div style="display: flex; flex-wrap: wrap">
      <TerminTimeComponent v-for="time in times" :time="time" :key="time.time" />
    </div>
    <v-btn color="primary" class="mt-6" @click="printTime">PrintTime</v-btn>
  </div>
</template>

<script>
import moment from "moment";
import TerminTimeComponent from "./TerminTimeComponent";

export default {
  name: "TerminComponent",
  components: { TerminTimeComponent },
  props: {
    step: {
      default: 15,
      required: false
    },
    service_plus: {
      default: false,
      required: false
    },
    service_time: {
      default: 30,
      required: false
    },
    booked_time: {
      default: () => [],
      required: false
    },
    work_time: {
      required: true
    }
  },
  data() {
    return {
      times: [],
    }
  },
  // watch: {
  //   service_time() {
  //     this.printTime()
  //   }
  // },
  created() {
    this.printTime()
  },
  methods: {
    addZ(num) {
      return num < 10 ? '0' + num : num
    },
    printTime() {
      const work_time_start_h = parseInt(this.work_time.start.split(':')[0])
      const work_time_start_m = parseInt(this.work_time.start.split(':')[1])

      const work_time_end_h = parseInt(this.work_time.end.split(':')[0])
      const work_time_end_m = parseInt(this.work_time.end.split(':')[1])

      let hour = parseInt(work_time_start_h)
      let min  = parseInt(work_time_start_m)

      let result = []
      const step = this.step

      for (let i = hour; i <= work_time_end_h; i++) {
        for (let x = min; x <= 60; x += step) {
          if (work_time_end_h === i && work_time_end_m === x) {
            min = 0
            break
          }
          if (x === 60) {
            min = 0
            break
          }
          let time = `${this.addZ(i)}:${this.addZ(x)}`
          let status = this.IsBookedTime(time)

          result.push({time: time, status: status})
        }
      }

      this.times = result

      if (this.service_plus) {
        this.servicePlus(this.service_time)
      }
    },
    IsBookedTime(time) {
      return this.booked_time.find(fr => fr === time) === undefined
    },
    timeRounding(time) {
      const cont = (Math.ceil((time / this.step)) * this.step)
      for (let i = this.step; i <= cont; i += this.step) {
        if (cont <= i) {
          return i
        }
      }
    },
    servicePlus(time) {
      time = this.timeRounding(time)
      const current_time = moment().format('YYYY-MM-DD')

      this.times = this.times.map(m_time => {
        const end_time = moment(`${current_time} ${m_time.time}`).add(time, 'minutes').format('HH:mm')
        const days = this.times

        let status = m_time.status
        let time_is_doing = false

        for (let i = 0; i < days.length; i++) {
          if (end_time === days[i].time) {
            break
          }
          if (m_time.time === days[i].time) {
            time_is_doing = true
          }
          if (time_is_doing) {
            if (days[i].status === false) {
              status = false
            }
          }
        }

        m_time.status = status
        return m_time
      })
    },
  }
}
</script>